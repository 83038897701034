"use client";

import { Heading, Link, Text } from "@chakra-ui/react";
import RouterLink from "next/link";

export function NotFoundComponent() {
  return (
    <>
      <Heading>Hoppla!</Heading>
      <Text fontSize="6xl" mt={4} textAlign="center">
        <span role="img" aria-label="nachdenkliches Gesicht">
          🤔
        </span>
      </Text>
      <Text mt={4}>
        Hast du dich verlaufen? Diese Seite existiert nicht!{" "}
        <Link as={RouterLink} href="/" color="blue.500">
          Auf zurück zur Homepage!
        </Link>
      </Text>
    </>
  );
}
